<template>
	
		<div v-if="openNew" class="modal addNew">
			<h2 class="text-xl font-bold">Delete Row?</h2>
			<div class="button button--secondary">Delete</div>
			
			
		</div>


</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'DeleteRow',
	components: {
		
	},
	computed: {
		...mapState(['originalSheet','confirmDelete'])
	},
	props: [
		"openNew"
	],
	data() {
		return {
			
		}
	},
	methods: {
		async deleteRow(event) {
			
			await this.originalSheet.deleteRow(this.confirmDelete);
			this.$store.dispatch('loadSheet');
			this.$emit("closeNew");
		},
		cancelNew() {
			this.$emit("closeNew");
		}
	},
	mounted() {
		
	}
}


</script>


<style lang="scss" scoped>

	.modal {
		height: 80vh;
		overflow: scroll;
		position: fixed;
		z-index: 99;
		left: 50%;
		top: 50px;
		transform: translateX(-50%);
	}
	form {
		input[type=email],
		input[type=text],
		input[type=password] {
			max-width: none;
		}
	}

	.category {
		border-bottom: 1px solid #ccc;
		padding-bottom: 3rem;
		margin-bottom: 3rem;
		display: flex;
		flex-wrap: wrap;
		gap: 2rem;
	}
	.addNew {
		.field {
			width: calc(50% - 1rem);
			margin-bottom: 0;

			&--full {
				width: 100%;
			}
		}

		h3 {
			display: block;
			width: 100%;
		}
	}
	
	.loginForm {
		
		margin: 50px auto 0;
	}


</style>
