<template>
	<tr :class="saving ? 'loading' : ''">        
		<Cell ref="account" col="Account" inputFormat="text" :cell="row.account" :rowNum="row.rowNum" :currentTier="row.currentTiering" :currentBand="row.currentBand" :futureBand="row.futureBand" :futureTier="row.futureTiering" :expiry="row.expiry" />
		<Cell v-for="(cell,index) in row.cols" :key="cell.id" :inputFormat="index.includes('date') ? 'date' : 'number'" :col="index" :cell="cell" :rowNum="row.rowNum" />
      
      <td v-if="isAdministrator" class="text-center delete" @click="deleteRow()">
         <img v-if="!saving" src="@/assets/cross.svg" alt="">
         <img v-if="saving" src="@/assets/preloader.svg" alt="">
      </td>      
   </tr>
</template>

<script>
	import Cell from '@/components/Cell.vue';
	import { mapState } from 'vuex';

	export default {
		name: "Row",
		props: ["row","headers"],
		components: {
			Cell
		},
		data() {
			return {
				rowData: this.row,
            saving: false
         }
		},
		computed: {
			...mapState(['tiering', 'confirmDelete','originalRows', 'docID', 'creds', 'sheetloading', 'user','isAdministrator','isContributor'])
		},
		methods:{
			deleteRow() {
            this.saving = true;
            this.$refs.account.saveCell("-" + this.row.account);
         }
		},
      watch: {
         sheetloading: function(val) {
            if(val == false) {
               this.saving = false;
            }
         }
      }
      
	}
	
</script>

<style lang="scss" scoped>
	
   tr.loading {
      opacity: 0.4;
   }

</style>