import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue3-cookies'

const app = createApp(App);
app.use(VueCookies, {
    expireTimes: "3d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
});
app.use(store).use(router).mount('#app');