<template>
	<td valign="center">
		<div :class="['flex','items-center','relative',cellloading ? 'cellloading' : '']">
			
			<div v-if="currentTier" class="flex items-center">
				<div :class="['expiry', 'tooltip', getExpiry]"><span class="label">Contract expires: {{ expiry }}</span></div>
            <div :class="['tiering', 'tooltip', 'current', getCurrentTier]">C <span :class="['label', getCurrentTier]">{{currentTier}}</span></div>
				<div :class="['tiering', 'tooltip', 'future', getFutureTier]">F <span :class="['label', getFutureTier]">{{futureTier}}</span></div>
			</div>
			
         <div v-if="isContributor">
            <select v-if="metaData[col] && metaData[col].values.length > 0" ref="select" class="custom-select custom-select-sm" @change="saveCell($event)">
               <option></option>
               <option v-for="option in metaData[col].values" :key="option.id" :value="option" :selected="option == cell">{{option}}</option>
            </select>
            <input v-else-if="inputFormat == 'date'" @change="saveCell($event)" :type=inputFormat v-model.lazy="currentCell">
            <input v-else @blur="saveCell($event)" :type="inputFormat" v-model.lazy="currentCell" @keyup.enter="saveCell($event)">
         </div>
         <div v-else>
            <span>{{currentCell}}</span>
         </div>

		</div>
	</td>
	
</template>

<script>
	import { mapState } from 'vuex';
	import Preloader from '@/components/Preloader.vue';
	const { GoogleSpreadsheet } = require('google-spreadsheet');

	export default {
		name: "Cell",
		props: ["inputFormat", "cell","col","rowNum","currentTier","futureTier","currentBand","futureBand","expiry"],
		components: {
			//Preloader
		},
		computed: {
			...mapState(['metaData', 'creds', 'docID', 'sheetID','originalSheet','sheetloading', 'user','isContributor']),
			getCurrentTier: function() {
				let _class;				
				if(parseInt(this.currentBand) == 1) _class = 'bg--gold';
				if(parseInt(this.currentBand) == 2) _class = 'bg--silver';
				if(parseInt(this.currentBand) == 3) _class = 'bg--bronze';

				return _class;
			},
			getFutureTier: function() {
				let _class;
				if(parseInt(this.futureBand) == 1) _class = 'bg--gold';
				if(parseInt(this.futureBand) == 2) _class = 'bg--silver';
				if(parseInt(this.futureBand) == 3) _class = 'bg--bronze';

				return _class;
			},
         getExpiry: function() {
            let _class;
            const today = new Date();
            const expiry = new Date(this.expiry);

            if(expiry < today) {
               _class="expiry_0";
            } else if(this.monthDiff(expiry, today) <= 6) {
               _class="expiry_1";
            } else {
               _class="expiry_2";
            }
            
            return _class;
         }
		},
		data() {
			return {
				currentCell: this.cell,
				editMode: false,
				cellSheet:{},
				saveOffset: 5,
				cellRef:'',
				cellData:{},
				cellloading: false,
            format: this.inputFormat
			}
		},
		updated() {
			this.setRef();
		},
		mounted() {
			this.setRef();
		},
		created() {
		
		},
		methods:{
			setRef() {
				this.cellRef = this.numToColumn(this.originalSheet.headerValues.indexOf(this.col)+1) + this.rowNum;
			},
			async saveCell(event) {
				if(this.cellRef) {
					this.cellloading = true;
					this.editMode = true;
					const doc = new GoogleSpreadsheet(this.docID);
					await doc.useServiceAccountAuth(this.creds);
					await doc.loadInfo(); 
					this.cellSheet = doc.sheetsById[this.sheetID];
					await this.cellSheet.loadCells(this.cellRef);
					this.cellData = this.cellSheet.getCellByA1(this.cellRef);
	
					if(event.target) {
                  event.target.blur();
					   this.cellData.value = event.target.value;
               } else {
                  //being called from parent, get value passed instead of target.value
                  this.cellData.value = event;
               }
					await this.cellSheet.saveUpdatedCells();
					
					this.$store.dispatch('loadSheet');
					this.editMode = false;					
				}
			},
			numToColumn(num) {
				let s = '', t;
				while (num > 0) {
					t = (num - 1) % 26;
					s = String.fromCharCode(65 + t) + s;
					num = (num - t)/26 | 0;
				}
				return s || undefined;
			},
			preventDefault() {
				return false;
			},
         monthDiff(d1, d2) {
            let months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();

            return months <= 0 ? 0 : months;
         }
		},
		watch: {
			cell: function(val) {
				this.currentCell = this.cell; 
			},
			sheetloading: function(val) {
				if(!val) {
					this.cellloading = false;
				}
			}
		}
	}
	
</script>

<style lang="scss" scoped>
	td {
		vertical-align: middle;
		font-size: 14px;
		text-align: left;
	}


	select {
		border: none;
		padding-left: 0;
      cursor: pointer;
	}
	input {
		&[type="text"],
		&[type="number"],
		&[type="password"],
		&[type="email"] {
			padding: 5px 10px;
			font-size: 14px;
         cursor: pointer;
		}
	}
	

	.cellloading {
		position: relative;
		&:after {
			content: "";
			position: absolute;
			z-index: 2;
			background: rgba(255,255,255,0.6);
			width: 100%;
			height: 100%;
		}
	}

	.loader {
		background: rgba(255,255,255,0.8);
		width: 100%;
	}

	.tiering {
		font-size: 13px;
		color:white;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		font-weight: 500;
		text-align: center;
		margin-right: 3px;
		position: relative;
		cursor: pointer;

		&.future {
			margin-right: 10px;
		}
		
		// &:hover span {
		// 	opacity: 1;
		// }

		// span {
		// 	font-size: 14px;
		// 	color: white;
		// 	position: absolute;
		// 	z-index: 2;
		// 	left: 102%;
		// 	bottom: 14px;
		// 	padding: 3px 10px;
		// 	font-weight: 600;;
		// 	border-radius: 5px;
		// 	opacity: 0;
		// 	pointer-events: none;
		// 	transition: all 0.3s;
		// }
	}
</style>