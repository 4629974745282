<template>
	<div class="wrapper mb-8">	
	
	<AddRow :openNew="openNew" @closeNew="closeNew()" />
   <DeleteRow :openDelete="openDelete" @closeDelete="closeDelete()" />

   <div v-if="devMode" class="devMode flex justify-center">
      <h3>Warning: development mode!</h3>
      <p class="ml-20"><a class="underline" :href="`https://docs.google.com/spreadsheets/d/${docID}`" target="_blank">DEV Google Sheet</a></p>
   </div>
      
	<div class="header pt-4 border-b">
		<div class="flex relative items-end container-fluid pb-4">
			<div class="flex-1">
				<img src="@/assets/calor-logo.png" class="logo inline-block mr-10">
				<h4 class="mb-0 align-bottom lg:inline-block hidden">Account classification</h4>
			</div>
			<div class="viewToggle">
				<router-link to="/current" class="active"><span>Current</span></router-link>
				<router-link to="/future"><span>Future</span></router-link>
				<router-link to="/general"><span>General</span></router-link>
			</div>
			<div class="btn-toolbar">
				<div class="flex items-center">
					<div @click="filterActive = !filterActive" class="button button--secondary mr-3"><span><img src="@/assets/filter-icon.svg" alt=""></span><span>Filter</span></div>
					<div v-if="isAdministrator" @click="newRow()" class="button mr-3"><span>+</span><span>Add row</span></div>
					<router-link to="/report" class="button button--secondary mr-3"><span>+</span><span>Report</span></router-link>
                              <!-- <a href="https://datastudio.google.com/s/kWLfe3HxKVQ" target="_blank" class="button button--secondary mr-3">View results</a> -->
				</div>
			</div>
		</div>
	</div>

	<div v-if="filterActive" class="container-fluid">
		<div class="filter mt-4 flex gap-x-5 items-center">
			
         <select @change="filterResults($event)">
				<option value="">Select Market Sector</option>
				<option v-for="sector in metaData['Market Sector'].values" :key="sector.id" :value="sector">{{sector}}</option>
			</select>

         <div @click="orderResults($event, 'current')" class="button button--secondary">Order by current score</div>
         <div @click="orderResults($event, 'future')" class="button button--secondary">Order by future score</div>
         
      </div>
	</div>

	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import AddRow from '@/components/AddRow';
   import DeleteRow from '@/components/DeleteRow'

	export default {
		name: "Header",
		components: {
			AddRow,
         DeleteRow
		},
		props: ["header"],
		data() {
			return {
				openNew: false,
				openDelete: false,
				filterActive: null,
            devMode: process.env.VUE_APP_ENV == "development" ? true : false
			}
		},
		computed: {
			...mapState(['metaData', 'currentView', 'filter', 'docID','isAdministrator'])
		},
		methods: {
			filterResults(event) {
				if(event.target.value) {
					this.$store.commit("filterByMarketSector", event.target.value);
				} else {
					this.$store.commit('resetFilter');
				}
			},
         orderResults(event, view) {
            //event.target.classList.toggle('active');
            this.$store.commit("orderResults", view)
         }, 
			newRow() {
				this.openNew = true;
			},
			closeNew() {
				this.openNew = false;
			},
         deleteRow() {
				this.deleteNew = true;
			},
			deleteNew() {
				this.deleteNew = false;
			},
			
		},
		mounted() {
			
		},
		watch: {
         filter: function(va) {

         },
			currentView: function(val) {
				if(!val) {
					this.filterActive = false;
				}
			},
			filterActive: function(val) {
				this.$store.commit('resetFilter');
			}
		}
	}
	
</script>

<style lang="scss" scoped>
	.header {
		background: white;
	}
	.logo {
		max-width: 130px;
	}
	.viewToggle {
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
		line-height: 1;
		font-size: 15px;

		a {
			font-weight:bold;
			padding: 0 30px;
			display: inline-block;
			opacity: 0.4;
      
			span {
				display: block;
				margin-bottom: 20px;
			}

			&.router-link-active {
				opacity: 1;
				border-bottom: 3px solid $calorRed;
			}
		}
	}

	.filter {
		background: #FFFFFF;
		border: 1px solid #C7C7C7;
		border-radius: 10px;
		padding: 15px 20px;

		select {
			border: 1px solid #ccc;
			width: auto;
		}
	}

   .devMode {
      background:red;
      color: white;
      padding: 10px 20px;
   }

   @media screen and (max-width:1220px) {
      .viewToggle a {
         padding: 0 15px;
      }
      .btn-toolbar .button {
         span:first-child {
            display: none;
         }
      }
   }
</style>