<template>
	<router-view />

	<div class="footer">
		<div class="container-fluid flex items-end">
			<div class="copyright flex-1">&copy; Copyright Calor 2023</div>
			<div class="freestyle">Powered by <img src="@/assets/fs-logo.svg"></div>
		</div>
	</div>
</template>

<script>

import axios from 'axios';

export default {
	name: 'App',
	components: {
		
	},
	mounted() {
      if(this.$route.name != 'Login') {
         //check token is valid               
         axios({
            url: "https://freestyle-prioritisation-app.azurewebsites.net/api/auth/authenticate/token",
            method: "POST",
            headers: {
            Authorization: `Bearer ${this.$cookies.get('auth')}`
            },
         })
         .then((resp) => {
            this.loadData();
            this.$router.push({
            	name: "Current"
            });
         })
         .catch((err) => {
            console.log('not logged in, redirecting', err);
            this.$router.push({
            	name: "Login"
            });
            
         });
      }

      this.checkUser('Administrator').then(response => this.$store.state.isAdministrator = response);
      this.checkUser('Contributor').then(response => this.$store.state.isContributor = response);
	},
   methods: {
      loadData() {
         this.$store.dispatch('loadSheet');
		   this.$store.dispatch('loadMeta');
      },
      async checkUser(role) {
         console.log('checking user role for: ', role)
         const inRole = await axios({
				url: `https://freestyle-prioritisation-app.azurewebsites.net/api/auth/userInRole/${role}`,
				method: "GET",
            headers: {
              Authorization: `Bearer ${this.$cookies.get('auth')}`,
              "Content-Type": "application/json",
            },
			})
			.then((resp) => {
            console.log('user in role', role)
            return true;
			})
			.catch((err) => {
				console.log('user not in role: ', role);
				return false;
			});

         return inRole;
      }
   }
}

</script>

<style lang="scss">
html,body {
	height: 100%;
}

#app {
	background-color: #F8F8F8;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100%;
}

.footer {
	padding: 40px 0;
	font-size: 13px;
	line-height: 1;

	
	.freestyle {

		img {
			display: inline-block;
			vertical-align: bottom;
			max-width: 40px;
			margin-left: 10px;
		}
	}
}

</style>
