<template>	
	<div class="container-fluid table-container relative">
		<Preloader :loading="siteloading" />
		<div class="row" v-if="!siteloading">
				<div class="table-responsive">
					<table class="main">
						<thead class="">
						<tr>
							<th align="left" @click="sortData('account')" :class="[{active: activeSort.column == 'account'}, {invertSort: activeSort.invert && activeSort.column == 'account'}]"><div class="flex items-center"><span class="flex-1">Account</span><span class="arrow"></span></div></th>
							<HeaderRow :key="header.id" v-for="header in data.headers" v-bind:header="header" @click="sortData(header)" :class="[{active: activeSort.column == header}, {invertSort: activeSort.invert && activeSort.column == header}]" /> 
                     <th v-if="isAdministrator" valign="center"><img src="@/assets/trash.svg" alt=""></th>
                  </tr>
						</thead>
						<tbody>
							<Row :key="row.id" v-for="row in data.rows" :row="row" :headers="data.headers" />
						</tbody>
					</table>

               <!-- <div class="pagination f-full flex justify-center items-center">
                  <router-link :to="{name:'Current', params: {page:'2'}}" class="arrow arrow--prev"><img src="@/assets/arrow-left.svg" alt=""></router-link>
                  <div class="currentPage">{{ page == 0 ? 1 : page }}</div>
                  <router-link :to={getNextPage} class="arrow arrow--next"><img src="@/assets/arrow-right.svg" alt=""></router-link>
               </div> -->
				</div>
		</div>
	</div>
</template>

<script>
import HeaderRow from '@/components/HeaderRow.vue';
import Row from '@/components/Row.vue';
import Preloader from '@/components/Preloader.vue';
import { mapState } from 'vuex';
const { GoogleSpreadsheet } = require('google-spreadsheet');
import axios from 'axios';

export default {
	name: 'GoogleSheet',
	components: {
		HeaderRow,
		Row,
		Preloader,
		//EditRow
	},
	props: {
		data: {
			type: Object,
			required: true
		},
		view: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			activeRow: null,
			sheetData: this.data,
			activeSort: {
				column: null,
				invert: false 
			},
         page: 0,
         pageLimit: 50
		}
	},
	updated() {
		this.sheetData = this.data
	},
   mounted() {
      
   },
	created() {
		this.page = this.$route.params.page ? this.$route.params.page : 0;
	},
	computed: {
		...mapState(['title', 'siteloading','sort', 'user', 'view', 'isAdministrator','isContributor']),
		paginatedData() {         
         const pagedArr = [];

         for(let i = (this.pageLimit*this.page); i< ((this.pageLimit*this.page)+this.pageLimit); i++) {
            pagedArr.push(this.data.rows[i]);
         }
         return pagedArr;
      },
      getPrevPage: function() {
         let pageUrl = this.page-1;
         if(pageUrl < 0) pageUrl = 0;
         return 'test';
//         return this.view + '/' + pageUrl;

      },
      getNextPage: function() {
         let pageUrl = this.page+1;
         
         return `${this.view}/${pageUrl}`;
      }
	},
	methods:{
		editRow(row) {
			this.activeRow = row;
			console.log(row)
		},
		disableEdit() {
			this.activeRow = null;
		},
		sortData(header) {
			// const view = this.view;

			// if(this.activeSort.column == header) {
			// 	//check if we need to invert direction, or reset
			// 	if(this.activeSort.invert) {
			// 		//reset sorting
			// 		this.$store.commit("resetFilter");
			// 		this.activeSort = {};
			// 	} else {
			// 		//flip sorting direction for current active column
			// 		this.$store.commit("sortByHeader", {
			// 			header:header,
			// 			invert:true
			// 		});
			// 		this.activeSort.invert = true;
			// 	}
			// } else {
			// 	//no sorting active, set
			// 	this.$store.commit("sortByHeader", {
			// 		header:header,
			// 		invert:false
			// 	});
		
			// 	this.activeSort.column = header;
			// 	this.activeSort.invert = false;
			// }
		}
	},
	watch: {
		sort: function(val) {
			if(!val) this.activeSort = {};
		}
	}
}
</script>


<style lang="scss" scoped>

	

	.editMode {
		position: fixed;
		z-index: 99;
		width: 100%;
		height: 100vh;
		top:0;
		left: 0;
		background-color: rgba(0,0,0,0.9);
		color: white;
	}

</style>
